import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Ingredients`}</h1>
    <h3>{`gluten free, dairy free, organic, omnivore cooking`}</h3>
    <h5>{`SALT`}</h5>
    <ul>
      <li parentName="ul">{`sea salt, French grey celtic`}</li>
      <li parentName="ul">{`nutritional yeast`}</li>
    </ul>
    <h5>{`FAT`}</h5>
    <ul>
      <li parentName="ul">{`coconut oil, unrefined`}</li>
      <li parentName="ul">{`hummus`}</li>
      <li parentName="ul">{`avocado`}</li>
      <li parentName="ul">{`cashew dressing 🥵🔥`}</li>
      <li parentName="ul">{`almond, roasted, chopped `}</li>
      <li parentName="ul">{`coconut milk`}</li>
      <li parentName="ul">{`olive oil`}</li>
    </ul>
    <h5>{`ACID`}</h5>
    <ul>
      <li parentName="ul">{`🍋`}</li>
      <li parentName="ul">{`wine, red`}</li>
      <li parentName="ul">{`🧄`}</li>
      <li parentName="ul">{`🍅, 🥫`}</li>
      <li parentName="ul">{`curry sauce, thai, green`}</li>
      <li parentName="ul">{`lime`}</li>
      <li parentName="ul">{`red pepper flakes`}</li>
      <li parentName="ul">{`ginger`}</li>
      <li parentName="ul">{`rice vinegar 🍶`}</li>
    </ul>
    <hr></hr>
    <ul>
      <li parentName="ul">{`turkey, ground`}</li>
      <li parentName="ul">{`salmon`}</li>
      <li parentName="ul">{`egg, outdoor access`}</li>
    </ul>
    <hr></hr>
    <ul>
      <li parentName="ul">{`chard, swiss`}</li>
      <li parentName="ul">{`kale chopped`}</li>
      <li parentName="ul">{`peas`}</li>
      <li parentName="ul">{`supergreens, wilted`}</li>
      <li parentName="ul">{`carrot`}</li>
      <li parentName="ul">{`spinach`}</li>
      <li parentName="ul">{`butternut squash, cubed`}</li>
      <li parentName="ul">{`potatoes, red, roasted`}</li>
      <li parentName="ul">{`brussel sprouts, roasted`}</li>
    </ul>
    <hr></hr>
    <ul>
      <li parentName="ul">{`lentils, beluga `}</li>
      <li parentName="ul">{`wild rice, warm`}</li>
      <li parentName="ul">{`red rice`}</li>
      <li parentName="ul">{`sweet potato`}</li>
      <li parentName="ul">{`chicken breast `}</li>
      <li parentName="ul">{`hard boiled egg `}</li>
    </ul>
    <hr></hr>
    <ul>
      <li parentName="ul">{`maple syrup`}</li>
      <li parentName="ul">{`all purpose gluten free flour`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      